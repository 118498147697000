import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../app/routes";
import {useDeletePromotionMutation} from "../../api/blooshApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);

    const [deletePromotion] = useDeletePromotionMutation();

    return (
        <>
            <div style={{flexDirection: "row"}}>
                <Button
                    label="Edytuj"
                    icon={PrimeIcons.PENCIL}
                    onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
                />
                <Button label="Usuń" icon={PrimeIcons.TRASH} onClick={() => setVisible(true)}/>
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Jesteś pewny, że chcesz usunąć ten rekord?"
                    header="Potwierdzenie"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                    accept={() => deletePromotion({id: row.id})}
                />
            </div>
        </>
    );
};

const promotionActionTemplate = (row: any) => <Actions row={row}/>;
export default promotionActionTemplate;
