import React, { FC } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Nav from "./components/Navbar";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./tabs/home/Home";
import Articles from "./tabs/article/Articles";
import ProtectedRoute from "./utils/ProtectedRoute";
import { ToastContainer } from "react-toastify";

import "primereact/resources/themes/soho-dark/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useKeycloak } from "@react-keycloak-fork/web";
import { ProgressSpinner } from "primereact/progressspinner";
import { routes } from "./app/routes";
import UpdateArticle from "./tabs/article/UpdateArticle";
import Settings from "./tabs/settings/Settings";
import Categories from "./tabs/settings/category/Categories";
import UpdateCategory from "./tabs/settings/category/UpdateCategory";
import ArticleComments from "./tabs/article/comments/ArticleComments";
import Promotions from "./tabs/promotion/Promotions";
import UpdatePromotion from "./tabs/promotion/UpdatePromotion";
import Products from "./tabs/product/Products";
import UpdateProduct from "./tabs/product/UpdateProduct";
import ProductComments from "./tabs/product/comments/ProductComments";
import ProductCommentThreads from "./tabs/product/comments/thread/ProductCommentThreads";
import GroupCategories from "./tabs/settings/groupcategory/GroupCategories";
import UpdateGroupCategory from "./tabs/settings/groupcategory/UpdateGroupCategory";

const App: FC = () => {
  const { initialized } = useKeycloak();

  return (
    <>
      {!initialized ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Nav />
                <div
                  style={{ maxWidth: 1920, margin: "0 auto", marginTop: 10 }}
                >
                  <Outlet />
                </div>
              </ProtectedRoute>
            }
          >
            <Route path={routes.HOME} element={<Outlet />}>
              <Route
                path={`/${routes.HOME}`}
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={routes.ARTICLES} element={<Outlet />}>
              <Route
                path={`/${routes.ARTICLES}`}
                element={
                  <ProtectedRoute>
                    <Articles />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${routes.EDIT}/:id`}
                element={
                  <ProtectedRoute>
                    <UpdateArticle />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${routes.ADD}`}
                element={
                  <ProtectedRoute>
                    <UpdateArticle />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`:id/${routes.COMMENTS}`}
                element={
                  <ProtectedRoute>
                    <ArticleComments />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={routes.PROMOTIONS} element={<Outlet />}>
              <Route
                path={`/${routes.PROMOTIONS}`}
                element={
                  <ProtectedRoute>
                    <Promotions />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${routes.EDIT}/:id`}
                element={
                  <ProtectedRoute>
                    <UpdatePromotion />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${routes.ADD}`}
                element={
                  <ProtectedRoute>
                    <UpdatePromotion />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={routes.PRODUCTS} element={<Outlet />}>
              <Route
                path={`/${routes.PRODUCTS}`}
                element={
                  <ProtectedRoute>
                    <Products />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${routes.EDIT}/:id`}
                element={
                  <ProtectedRoute>
                    <UpdateProduct />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${routes.ADD}`}
                element={
                  <ProtectedRoute>
                    <UpdateProduct />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`:productId/${routes.COMMENTS}`}
                element={<Outlet />}
              >
                <Route
                  path={``}
                  element={
                    <ProtectedRoute>
                      <ProductComments />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`:id/${routes.THREADS}`}
                  element={
                    <ProtectedRoute>
                      <ProductCommentThreads />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
            <Route path={routes.SETTINGS} element={<Outlet />}>
              <Route
                path={`/${routes.SETTINGS}`}
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route path={routes.CATEGORIES} element={<Outlet />}>
                <Route
                  path={``}
                  element={
                    <ProtectedRoute>
                      <Categories />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${routes.EDIT}/:id`}
                  element={
                    <ProtectedRoute>
                      <UpdateCategory />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${routes.ADD}`}
                  element={
                    <ProtectedRoute>
                      <UpdateCategory />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path={routes.GROUP_CATEGORIES} element={<Outlet />}>
                <Route
                  path={``}
                  element={
                    <ProtectedRoute>
                      <GroupCategories />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${routes.EDIT}/:id`}
                  element={
                    <ProtectedRoute>
                      <UpdateGroupCategory />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${routes.ADD}`}
                  element={
                    <ProtectedRoute>
                      <UpdateGroupCategory />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      )}
      <ToastContainer theme="dark" />
    </>
  );
};

export default App;
