import React, { FC } from "react";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import productCommentThreadActionTemplate from "./Actions";
import { PrimeIcons } from "primereact/api";
import { routes } from "../../../../app/routes";
import TableWrapper from "../../../../components/table/TableWrapper";
import { useFindProductCommentThreadsByProductCommentIdQuery } from "../../../../api/blooshApi";

const ProductCommentThreads: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const { id: productCommentId = "", productId: productId = "" } = useParams();
  const { isLoading, data } =
    useFindProductCommentThreadsByProductCommentIdQuery({
      page: 0,
      size: 10000,
      sort: ["createdDate,desc"],
      productCommentId: productCommentId,
    });

  const columns = [
    { field: "username", header: "Username", sortable: true },
    {
      field: "content",
      header: "Treść",
      style: { textWrap: "unset", minWidth: 600 },
      sortable: true,
    },
    { field: "createdDate", header: "Data dodania", sortable: true },
    {
      field: "",
      header: "",
      body: productCommentThreadActionTemplate,
      style: { minWidth: 200 },
    },
  ];

  const headerButtons = (
    <>
      <Button
        onClick={() =>
          navigate(`/${routes.PRODUCTS}/${productId}/${routes.COMMENTS}`)
        }
        label="Wróć do listy komentarzy produktu"
        icon={PrimeIcons.BACKWARD}
      />
    </>
  );

  return (
    <>
      <TableWrapper
        header={`Dyskusja do komentarza`}
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        addButton={headerButtons}
        stateKey="product-comment-threads-list"
      />
    </>
  );
};

export default ProductCommentThreads;
