import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import promotionActionTemplate from "./Actions";
import { Button } from "primereact/button";
import { routes } from "../../app/routes";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { PromotionDto, useFindPromotionsQuery } from "../../api/blooshApi";

const columns = [
  { field: "name", header: "Nazwa", sortable: true },
  {
    field: "price",
    header: "Cena",
    body: (dto: PromotionDto) => dto.price + " PLN",
    sortable: true,
  },
  {
    field: "discountedPrice",
    header: "Cena po przecenie",
    body: (dto: PromotionDto) =>
      dto.discountedPrice ? dto.discountedPrice + " PLN" : "",
    sortable: true,
  },
  {
    field: "priority",
    header: "Priorytet",
    sortable: true,
    exportable: true,
  },
  {
    field: "imageUrl",
    header: "Podgląd",
    body: (row: PromotionDto) => <Image src={row.imageUrl} width="100" />,
  },
  { field: "", header: "", body: promotionActionTemplate },
];

const Promotions: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const { isLoading, data } = useFindPromotionsQuery({
    page: 0,
    size: 10000,
  });

  const addButton = (
    <Button onClick={() => navigate(routes.ADD)} label="Dodaj" />
  );

  return (
    <>
      <TableWrapper
        header="Promocje"
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        addButton={addButton}
      />
    </>
  );
};

export default Promotions;
