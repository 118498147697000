import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  initialArticleState,
  selectArticleState,
  setArticleData,
  setArticleProperty,
} from "../../slices/articlesSlice";
import { routes } from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import {
  useCreateArticleMutation,
  useFindArticleByIdQuery,
  useFindCategoriesQuery,
  useFindProductsQuery,
  useUpdateArticleMutation,
} from "../../api/blooshApi";
import UploadImageInput from "../../components/form/UploadImageInput";
import MultiSelectInput from "../../components/form/MultiSelectInput";
import MdEditorInput from "../../components/form/MdEditorInput";
import SelectInput from "../../components/form/SelectInput";

const UpdateArticle: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: categories } = useFindCategoriesQuery({
    page: 0,
    size: 10000,
  });
  const { isLoading: isPrepareLoading } = useFindArticleByIdQuery(
    { id: id },
    { skip: id === "" }
  );
  const { data: productsPage } = useFindProductsQuery({
    page: 0,
    size: 10000,
  });
  const [
    updateBanner,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateArticleMutation();
  const [
    createBanner,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateArticleMutation();

  const { article } = useAppSelector(selectArticleState);
  const {
    title,
    content,
    shortContent,
    imageKey,
    imageUrl,
    categoryId,
    priority,
    mentionedProductIds,
  } = article;

  const isSuccess = isUpdateSuccess || isCreateSuccess;

  useEffect(() => {
    dispatch(
      setArticleData({ property: "article", value: initialArticleState })
    );

    if (isSuccess) {
      navigate(`/${routes.ARTICLES}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    !!id
      ? updateBanner({ id: id, articleDto: article })
      : createBanner({ articleDto: article });
  };

  return (
    <>
      <div className="grid m-5">
        <h3 className="text-center p-1 mt-0">
          {!id ? `Dodaj artykuł` : `Zaaktualizuj artykuł ${title}`}
        </h3>
        <TextInput
          id="title"
          name="title"
          value={title}
          label="Tytuł"
          setProperty={setArticleProperty}
        />
        <TextInput
          id="shortContent"
          name="shortContent"
          value={shortContent}
          label="Skrócony opis (wyświetla się na liście przed wejściem w artykuł)"
          setProperty={setArticleProperty}
        />
        <MdEditorInput
          id="content"
          name="content"
          value={content}
          label="Treść"
          setProperty={setArticleProperty}
        />
        <SelectInput
          id="categoryId"
          options={
            categories?.content?.map((item) => {
              return { value: item.id, label: item.name };
            }) || []
          }
          name="categoryId"
          value={categoryId}
          label="Kategoria"
          setProperty={setArticleProperty}
        />
        <TextInput
          id="priority"
          name="priority"
          value={priority}
          label="Priorytet"
          setProperty={setArticleProperty}
        />
        <UploadImageInput
          id="imageKey"
          imageKeyProperty="imageKey"
          imageUrlProperty="imageUrl"
          imageKey={imageKey}
          imageUrl={imageUrl}
          label="Zdjęcie"
          setProperty={setArticleProperty}
        />
        <MultiSelectInput
          id="mentionedProductIds"
          options={
            productsPage?.content?.map((item) => {
              return { value: item.id, label: item.name };
            }) || []
          }
          name="mentionedProductIds"
          value={mentionedProductIds}
          label="Wspomniane produkty"
          setProperty={setArticleProperty}
        />
        <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
      </div>
    </>
  );
};

export default UpdateArticle;
