import React, {FC} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Image} from "primereact/image";
import {FileUpload} from "primereact/fileupload";
import keycloak from "../../Keycloak";

type TUploadImageInputProps = {
    id: string;
    imageKeyProperty: string;
    imageUrlProperty: string;
    imageKey: string;
    imageUrl: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
    index?: number;
};

const UploadImageInput: FC<TUploadImageInputProps> = ({
                                                          id,
                                                          imageKeyProperty,
                                                          imageUrlProperty,
                                                          imageKey,
                                                          imageUrl,
                                                          label,
                                                          setProperty,
                                                          index,
                                                      }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="grid" style={{marginBottom: 20}}>
                <div className="col">
                    {imageUrl && <Image id={`${id}-preview`} src={imageUrl} alt={label} width="200"/>}
                </div>
                <div className="col">
                    <FileUpload
                        id={id}
                        name="image"
                        accept="image/*"
                        url="/api/images"
                        mode="basic"
                        maxFileSize={1000000}
                        chooseOptions={{label: label}}
                        auto
                        onUpload={(e) => {
                            dispatch(setProperty({property: imageKeyProperty, value: JSON.parse(e.xhr.response).imageKey, index: index}));
                            dispatch(setProperty({property: imageUrlProperty, value: JSON.parse(e.xhr.response).imageUrl, index: index}));
                        }}
                        onBeforeSend={(e) =>
                            e.xhr.setRequestHeader("Authorization", `Bearer ${keycloak.token}`)
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default UploadImageInput;
