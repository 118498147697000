import {useKeycloak} from "@react-keycloak-fork/web";
import React, {FC, ReactNode} from "react";
import {NotAuthorized} from "../components/NotAuthorized";

export const ProtectedRoute: FC<{ children: ReactNode }> = ({children}) => {
    const {keycloak, initialized} = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    if (initialized && !isLoggedIn) {
        keycloak.login();
    }

    return <>{isLoggedIn ? children : <NotAuthorized/>}</>;
};

ProtectedRoute.displayName = "ProtectedRoute";
export default ProtectedRoute;
