import React, {FC} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {SelectItemOptionsType} from "primereact/selectitem";
import {MultiSelect} from "primereact/multiselect";

type TMultiSelectInputProps = {
    id: string;
    options: SelectItemOptionsType,
    name: string;
    value: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
    optionValue?: string;
    optionLabel?: string;
};

const MultiSelectInput: FC<TMultiSelectInputProps> = (
    {
        id,
        options,
        name,
        value,
        label,
        setProperty,
        optionValue,
        optionLabel
    }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field field pt-2 pb-2 w-full">
          <span className="p-float-label">
            <MultiSelect
                id={id}
                options={options}
                value={value}
                onChange={(e) =>
                    dispatch(
                        setProperty({property: name, value: e.target.value})
                    )
                }
                optionValue={optionValue}
                optionLabel={optionLabel}
                className={"w-full"}
                filter
            />
            <label htmlFor={name}>{label}</label>
          </span>
            </div>
        </>
    );
};

export default MultiSelectInput;
