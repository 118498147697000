import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { routes } from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  useCreateGroupCategoryMutation,
  useFindGroupCategoryByIdQuery,
  useUpdateGroupCategoryMutation,
} from "../../../api/blooshApi";
import {
  initialGroupCategoryState,
  selectSettingState,
  setGroupCategoryProperty,
  setSettingData,
} from "../../../slices/settingsSlice";

const UpdateGroupCategory: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading: isPrepareLoading } = useFindGroupCategoryByIdQuery(
    { id: id },
    { skip: id === "" }
  );
  const [
    updateGroupCategory,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateGroupCategoryMutation();
  const [
    createGroupCategory,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateGroupCategoryMutation();

  const { groupCategory } = useAppSelector(selectSettingState);
  const { name } = groupCategory;

  const isSuccess = isUpdateSuccess || isCreateSuccess;

  useEffect(() => {
    dispatch(
      setSettingData({
        property: "groupCategory",
        value: initialGroupCategoryState,
      })
    );

    if (isSuccess) {
      navigate(`/${routes.SETTINGS}/${routes.GROUP_CATEGORIES}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    !!id
      ? updateGroupCategory({ id: id, groupCategoryDto: groupCategory })
      : createGroupCategory({ groupCategoryDto: groupCategory });
  };

  return (
    <>
      <div className="grid m-5">
        <h3 className="text-center p-1 mt-0">
          {!id
            ? `Dodaj grupę kategorii`
            : `Zaaktualizuj grupę kategorii ${name}`}
        </h3>
        <TextInput
          id="name"
          name="name"
          value={name}
          label="Nazwa"
          setProperty={setGroupCategoryProperty}
        />
        {isCreateLoading || isUpdateLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
        )}
      </div>
    </>
  );
};

export default UpdateGroupCategory;
