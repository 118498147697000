import React, {CSSProperties, FC, HTMLInputTypeAttribute} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Checkbox} from "primereact/checkbox";

type TCheckboxInputProps = {
    id: string;
    name: string;
    value: boolean;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
    style?: CSSProperties,
    index?: number,
    disabled?: boolean
    type?: HTMLInputTypeAttribute
};

const CheckboxInput: FC<TCheckboxInputProps> = ({
                                                    id,
                                                    name,
                                                    value,
                                                    label,
                                                    setProperty
                                                }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field pt-2 pb-2 w-full">
                <span className="p-float-label">
                    <div className="flex align-items-center">
                        <Checkbox inputId={id} name={name}
                                  onChange={(e) => dispatch(setProperty({property: name, value: e.checked}))}
                                  checked={value}/>
                    </div>
                    <label htmlFor={name} className="ml-5">{label}</label>
                </span>
            </div>
        </>
    );
};

export default CheckboxInput;
