import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticleDto } from "../api/blooshApi";

type TArticleState = {
  article: ArticleDto;
  articleFilter: string;
};

export const initialArticleState: ArticleDto = {
  id: "",
  title: "",
  shortContent: "",
  content: "",
  imageKey: "",
  imageUrl: "",
  categoryId: "",
  priority: 0,
  mentionedProductIds: [],
};

const initialState: TArticleState = {
  article: initialArticleState,
  articleFilter: "",
};

export const articlesSlice = createSlice({
  name: "ARTICLE",
  initialState,
  reducers: {
    setArticleData: <Property extends keyof TArticleState>(
      state: TArticleState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TArticleState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
    setArticleProperty: <Property extends keyof ArticleDto>(
      state: TArticleState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: ArticleDto[Property] }>
    ) => {
      const { property, value } = payload;
      state.article[property] = value;
    },
  },
});

export const { setArticleData, setArticleProperty } = articlesSlice.actions;

export const selectArticleState = (state: any) => state.articles;

export default articlesSlice.reducer;
