import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  initialPromotionState,
  selectPromotionState,
  setPromotionData,
  setPromotionProperty,
} from "../../slices/promotionsSlice";
import { routes } from "../../app/routes";
import {
  useCreatePromotionMutation,
  useUpdatePromotionMutation,
  useFindPromotionByIdQuery,
  useFindCategoriesQuery,
} from "../../api/blooshApi";
import TextInput from "../../components/form/TextInput";
import UploadImageInput from "../../components/form/UploadImageInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";

const UpdatePromotion: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: categories } = useFindCategoriesQuery({
    page: 0,
    size: 10000,
  });
  const { isLoading: isPrepareLoading } = useFindPromotionByIdQuery(
    { id: id },
    { skip: id === "" }
  );
  const [
    updatePromotion,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdatePromotionMutation();
  const [
    createPromotion,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreatePromotionMutation();

  const { promotion } = useAppSelector(selectPromotionState);
  const {
    name,
    categoryId,
    price,
    discountedPrice,
    referralLink,
    expiredDate,
    priority,
    imageKey,
    imageUrl,
  } = promotion;

  const isSuccess = isUpdateSuccess || isCreateSuccess;

  useEffect(() => {
    dispatch(
      setPromotionData({ property: "promotion", value: initialPromotionState })
    );

    if (isSuccess) {
      navigate(`/${routes.PROMOTIONS}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    !!id
      ? updatePromotion({ id: id, promotionDto: promotion })
      : createPromotion({ promotionDto: promotion });
  };

  return (
    <>
      <div className="grid m-5">
        <h3 className="text-center p-1 mt-0">
          {!id ? "Dodaj promocję" : `Edytuj promocję ${name}`}
        </h3>
        <TextInput
          id="name"
          name="name"
          value={name}
          label="Nazwa"
          setProperty={setPromotionProperty}
        />
        <SelectInput
          id="categoryId"
          options={
            categories?.content?.map((item) => {
              return { value: item.id, label: item.name };
            }) || []
          }
          name="categoryId"
          value={categoryId}
          label="Kategoria"
          setProperty={setPromotionProperty}
        />
        <TextInput
          id="price"
          name="price"
          value={price}
          type="number"
          label="Cena (PLN)"
          setProperty={setPromotionProperty}
        />
        <TextInput
          id="discountedPrice"
          name="discountedPrice"
          value={discountedPrice}
          type="number"
          label="Cena po przecenie (PLN)"
          setProperty={setPromotionProperty}
        />
        <TextInput
          id="referralLink"
          name="referralLink"
          value={referralLink}
          label="Link referencyjny"
          setProperty={setPromotionProperty}
        />
        <DateInput
          id="expiredDate"
          name="expiredDate"
          value={expiredDate}
          label="Data wygaśnięcia promocji"
          dateFormat="yy-mm-dd"
          setProperty={setPromotionProperty}
        />
        <TextInput
          id="priority"
          name="priority"
          value={priority}
          label="Priorytet"
          setProperty={setPromotionProperty}
        />
        <UploadImageInput
          id="imageKey"
          imageKeyProperty="imageKey"
          imageUrlProperty="imageUrl"
          imageKey={imageKey}
          imageUrl={imageUrl}
          label="Zdjęcie"
          setProperty={setPromotionProperty}
        />

        <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
      </div>
    </>
  );
};

export default UpdatePromotion;
