import React, {FC, ReactNode} from "react";
import {DataTable} from "primereact/datatable";
import {ProgressSpinner} from "primereact/progressspinner";
import {Column, ColumnBodyOptions} from "primereact/column";
import {useAppDispatch} from "../../app/hooks";

const TableWrapper: FC<{
    header?: string;
    sortField?: string;
    isLoading: boolean;
    data: any[] | undefined;
    columns: {
        field: string;
        header: string;
        body?: (data: any, options: ColumnBodyOptions) => ReactNode;
        style?: any;
        sortable?: boolean;
        hidden?: boolean;
    }[];
    size?: number;
    page?: number;
    first?: number;
    totalElements?: number;
    setData?: any;
    addButton: ReactNode;
    globalFilter?: string;
    rowClassName?: (data: any) => object | string | undefined;
    stateKey?: string;
    refDt?: any;
    footer?: ReactNode;
}> = ({
          header,
          sortField,
          isLoading,
          data,
          columns,
          setData,
          addButton,
          globalFilter,
          rowClassName,
          stateKey,
          refDt,
          footer
      }) => {
    const dispatch = useAppDispatch();

    const renderHeader = (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <h4 style={{margin: 0}}>{header}</h4>
            {addButton}
        </div>
    );

    const renderFooter = (
        <>
            {footer}
        </>
    );

    if (isLoading) {
        return <ProgressSpinner/>;
    }

    return (
        <>
            <DataTable
                dataKey="id"
                header={renderHeader}
                sortField={sortField}
                value={data}
                paginator
                rowsPerPageOptions={[10, 50, 100]}
                rows={10}
                sortMode="multiple"
                globalFilter={globalFilter}
                resizableColumns
                rowClassName={rowClassName}
                stateStorage="session"
                stateKey={stateKey}
                ref={refDt}
                footer={renderFooter}
            >
                {columns.map((col) => {
                    return (
                        <Column
                            key={col.field}
                            field={col.field}
                            header={col.header}
                            body={col.body}
                            style={col?.style}
                            sortable={col?.sortable}
                            hidden={col?.hidden}
                        />
                    );
                })}
            </DataTable>
        </>
    );
};

export default TableWrapper;
