export const cacheTags = {
  ARTICLES: "Articles",
  PROMOTIONS: "Promotions",
  PRODUCTS: "Products",
  CATEGORIES: "Categories",
  GROUP_CATEGORIES: "GroupCategories",
  ARTICLE_COMMENTS: "ArticleComments",
  PRODUCT_COMMENTS: "ProductComments",
  PRODUCT_COMMENT_THREADS: "ProductCommentThreads",
};
