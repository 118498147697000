import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { routes } from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import {
  ImageDto,
  useCreateProductMutation,
  useFindCategoriesQuery,
  useFindProductByIdQuery,
  useUpdateProductMutation,
} from "../../api/blooshApi";
import UploadImageInput from "../../components/form/UploadImageInput";
import {
  initialProductState,
  selectProductState,
  setProductData,
  setProductImageProperty,
  setProductProperty,
} from "../../slices/productsSlice";
import { PrimeIcons } from "primereact/api";
import MdEditorInput from "../../components/form/MdEditorInput";
import SelectInput from "../../components/form/SelectInput";

const UpdateProduct: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: categories } = useFindCategoriesQuery({
    page: 0,
    size: 10000,
  });
  const { isLoading: isPrepareLoading } = useFindProductByIdQuery(
    { id: id },
    { skip: id === "" }
  );
  const [
    updateBanner,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateProductMutation();
  const [
    createBanner,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateProductMutation();

  const { product } = useAppSelector(selectProductState);
  const {
    name,
    shortContent,
    content,
    referralLink,
    images,
    categoryId,
    priority,
    internalRate,
  } = product;

  const isSuccess = isUpdateSuccess || isCreateSuccess;

  useEffect(() => {
    dispatch(
      setProductData({ property: "product", value: initialProductState })
    );

    if (isSuccess) {
      navigate(`/${routes.PRODUCTS}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    !!id
      ? updateBanner({ id: id, productDto: product })
      : createBanner({ productDto: product });
  };

  const onImageAddButton = () => {
    const newImages = [...images, { imageKey: "", imageUrl: "" }];
    dispatch(setProductProperty({ property: "images", value: newImages }));
  };

  const onImageRemoveButton = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    dispatch(setProductProperty({ property: "images", value: newImages }));
  };

  return (
    <>
      <div className="grid m-5">
        <h3 className="text-center p-1 mt-0">
          {!id ? `Dodaj produkt` : `Zaaktualizuj produkt ${name}`}
        </h3>
        <TextInput
          id="name"
          name="name"
          value={name}
          label="Nazwa"
          setProperty={setProductProperty}
        />
        <TextInput
          id="shortContent"
          name="shortContent"
          value={shortContent}
          label="Skrócony opis (wyświetla się na liście przed wejściem w produkt)"
          setProperty={setProductProperty}
        />
        <MdEditorInput
          id="content"
          name="content"
          value={content}
          label="Treść"
          setProperty={setProductProperty}
        />
        <SelectInput
          id="categoryId"
          options={
            categories?.content?.map((item) => {
              return { value: item.id, label: item.name };
            }) || []
          }
          name="categoryId"
          value={categoryId}
          label="Kategoria"
          setProperty={setProductProperty}
        />
        <TextInput
          id="priority"
          name="priority"
          value={priority}
          label="Priorytet"
          setProperty={setProductProperty}
        />
        <TextInput
          id="referralLink"
          name="referralLink"
          value={referralLink}
          label="Link referencyjny"
          setProperty={setProductProperty}
        />

        {images.map((item: ImageDto, index: number) => {
          return (
            <>
              <UploadImageInput
                id={`imageKey-${index}`}
                index={index}
                imageKeyProperty="imageKey"
                imageUrlProperty="imageUrl"
                imageKey={item?.imageKey ?? ""}
                imageUrl={item?.imageUrl ?? ""}
                label={`Zdjęcie #${index}`}
                setProperty={setProductImageProperty}
              />
              <Button
                key={`remove-image-${index}`}
                style={{ height: "fit-content" }}
                className="ml-2"
                icon={PrimeIcons.TRASH}
                onClick={() => onImageRemoveButton(index)}
              />
            </>
          );
        })}
        <Button
          label="Dodaj zdjęcie"
          className="mt-5 mb-8 w-full"
          onClick={onImageAddButton}
        />

        <TextInput
          id="internalRate"
          name="internalRate"
          value={internalRate}
          label="Ocena redakcji"
          type="number"
          setProperty={setProductProperty}
        />

        <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
      </div>
    </>
  );
};

export default UpdateProduct;
