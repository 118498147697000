import { Action, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const Message = {
    SUCCESS: 'Operacja zakończona pomyślnie',
    ERROR: 'Wystąpił nieoczekiwany błąd. Zweryfikuj czy wprowadzono poprawne dane lub skontaktuj się z administratorem'
};

const SUCCESS_MUTATION = 'executeMutation/fulfilled';

const shouldDisplaySuccess = (action: Action) => action.type.endsWith(SUCCESS_MUTATION);

export const displayMessage: Middleware = () => next => action => {
    //display success for 'POST', 'PUT' and 'DELETE' operations
    if (shouldDisplaySuccess(action)) {
        toast.success(Message.SUCCESS);
    }

    if (isRejectedWithValue(action)) {
        //display error message
        toast.error(Message.ERROR);
    }
    return next(action);
};