import React, { FC } from "react";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteProductCommentThreadMutation } from "../../../../api/blooshApi";

const Actions: FC<{ row: any }> = ({ row }) => {
  const navigate = useNavigate();
  const { id: productCommentId = "" } = useParams();
  const [visible, setVisible] = React.useState(false);

  const [deleteProductThreadComment] = useDeleteProductCommentThreadMutation();

  return (
    <>
      <div className="flex gap-2">
        <Button
          tooltip="Usuń"
          icon={PrimeIcons.TRASH}
          onClick={() => setVisible(true)}
        />
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="Jesteś pewny? Ten komentarz z dyskusji zostanie usunięty na zawsze"
          header="Potwierdzenie"
          acceptLabel="Tak"
          rejectLabel="Nie"
          accept={() =>
            deleteProductThreadComment({
              productCommentId: productCommentId,
              id: row.id,
            })
          }
        />
      </div>
    </>
  );
};

const productCommentThreadActionTemplate = (row: any) => <Actions row={row} />;
export default productCommentThreadActionTemplate;
