import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import productActionTemplate from "./Actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/form/TextInput";
import { ProductDto, useFindProductsQuery } from "../../api/blooshApi";
import { routes } from "../../app/routes";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { selectProductState, setProductData } from "../../slices/productsSlice";
import { Image } from "primereact/image";

const Products: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { productFilter } = useAppSelector(selectProductState);
  const { isLoading, data } = useFindProductsQuery({
    page: 0,
    size: 10000,
  });

  const isDataLoading = isLoading;

  const columns = [
    { field: "name", header: "Nazwa", sortable: true, exportable: true },
    {
      field: "internalRate",
      header: "Ocena redakcji",
      sortable: true,
      exportable: true,
    },
    {
      field: "priority",
      header: "Priorytet",
      sortable: true,
      exportable: true,
    },
    {
      field: "images",
      header: "Podgląd",
      body: (row: ProductDto) => (
        <Image src={row.images?.at(0)?.imageUrl} width="100" />
      ),
    },
    {
      field: "",
      header: "",
      body: productActionTemplate,
      exportable: false,
    },
  ];

  return (
    <>
      <TextInput
        id="productFilter"
        name="productFilter"
        label=""
        value={productFilter}
        setProperty={setProductData}
        searchIcon
        placeholder="Szukaj"
      />
      <TableWrapper
        header="Produkty"
        isLoading={isDataLoading}
        data={data?.content}
        columns={columns}
        addButton={
          <Button
            onClick={() => navigate(routes.ADD)}
            label="Dodaj"
            icon={PrimeIcons.PLUS}
          />
        }
        globalFilter={productFilter}
        stateKey="products-list"
      />
    </>
  );
};

export default Products;
