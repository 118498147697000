import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { routes } from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  useCreateCategoryMutation,
  useFindCategoryByIdQuery,
  useFindGroupCategoriesQuery,
  useUpdateCategoryMutation,
} from "../../../api/blooshApi";
import {
  initialCategoryState,
  selectSettingState,
  setCategoryProperty,
  setSettingData,
} from "../../../slices/settingsSlice";
import CheckboxInput from "../../../components/form/CheckboxInput";
import SelectInput from "../../../components/form/SelectInput";

const UpdateCategory: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: groupCategories } = useFindGroupCategoriesQuery({
    page: 0,
    size: 10000,
  });
  const { isLoading: isPrepareLoading } = useFindCategoryByIdQuery(
    { id: id },
    { skip: id === "" }
  );
  const [
    updateCategory,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateCategoryMutation();
  const [
    createCategory,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateCategoryMutation();

  const { category } = useAppSelector(selectSettingState);
  const { name, groupCategoryId, articleOnly } = category;

  const isSuccess = isUpdateSuccess || isCreateSuccess;

  useEffect(() => {
    dispatch(
      setSettingData({ property: "category", value: initialCategoryState })
    );

    if (isSuccess) {
      navigate(`/${routes.SETTINGS}/${routes.CATEGORIES}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    !!id
      ? updateCategory({ id: id, categoryDto: category })
      : createCategory({ categoryDto: category });
  };

  return (
    <>
      <div className="grid m-5">
        <h3 className="text-center p-1 mt-0">
          {!id ? `Dodaj kategorię` : `Zaaktualizuj kateogrię ${name}`}
        </h3>
        <TextInput
          id="name"
          name="name"
          value={name}
          label="Nazwa"
          setProperty={setCategoryProperty}
        />
        <SelectInput
          id="groupCategoryId"
          options={
            groupCategories?.content?.map((item) => {
              return { value: item.id, label: item.name };
            }) || []
          }
          name="groupCategoryId"
          value={groupCategoryId}
          label="Grupa kategorii"
          setProperty={setCategoryProperty}
        />
        <CheckboxInput
          id="articleOnly"
          name="articleOnly"
          value={articleOnly}
          label="Tylko do artykułów"
          setProperty={setCategoryProperty}
        />
        {isCreateLoading || isUpdateLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
        )}
      </div>
    </>
  );
};

export default UpdateCategory;
