import React, { FC } from "react";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/routes";
import { useDeleteGroupCategoryMutation } from "../../../api/blooshApi";

const Actions: FC<{ row: any }> = ({ row }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState(false);

  const [deleteGroupCategory] = useDeleteGroupCategoryMutation();

  return (
    <>
      <div className="flex gap-2">
        <Button
          label="Edytuj"
          icon={PrimeIcons.PENCIL}
          onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
        />
        <Button
          label="Usuń"
          icon={PrimeIcons.TRASH}
          onClick={() => setVisible(true)}
        />
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="Jesteś pewny? Grupa kategorii zostanie usunięta na zawsze z systemu"
          header="Potwierdzenie"
          acceptLabel="Tak"
          rejectLabel="Nie"
          accept={() => deleteGroupCategory({ id: row.id })}
        />
      </div>
    </>
  );
};

const groupCategoryActionTemplate = (row: any) => <Actions row={row} />;
export default groupCategoryActionTemplate;
