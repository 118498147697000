import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import articleActionTemplate from "./Actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectArticleState, setArticleData } from "../../slices/articlesSlice";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/form/TextInput";
import { ArticleDto, useFindArticlesQuery } from "../../api/blooshApi";
import { routes } from "../../app/routes";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const Articles: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { articleFilter } = useAppSelector(selectArticleState);
  const { isLoading, data } = useFindArticlesQuery({
    page: 0,
    size: 10000,
  });

  const isDataLoading = isLoading;

  const columns = [
    { field: "title", header: "Tytuł", sortable: true, exportable: true },
    {
      field: "priority",
      header: "Priorytet",
      sortable: true,
      exportable: true,
    },
    {
      field: "imageUrl",
      header: "Podgląd",
      body: (row: ArticleDto) => <Image src={row.imageUrl} width="100" />,
    },
    {
      field: "",
      header: "",
      body: articleActionTemplate,
      exportable: false,
    },
  ];

  return (
    <>
      <TextInput
        id="articleFilter"
        name="articleFilter"
        label=""
        value={articleFilter}
        setProperty={setArticleData}
        searchIcon
        placeholder="Szukaj"
      />
      <TableWrapper
        header="Artykuły"
        isLoading={isDataLoading}
        data={data?.content}
        columns={columns}
        addButton={
          <Button
            onClick={() => navigate(routes.ADD)}
            label="Dodaj"
            icon={PrimeIcons.PLUS}
          />
        }
        globalFilter={articleFilter}
        stateKey="articles-list"
      />
    </>
  );
};

export default Articles;
