import React, { FC } from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import { routes } from "../../../app/routes";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/form/TextInput";
import { useAppSelector } from "../../../app/hooks";
import categoryActionTemplate from "./Actions";
import { PrimeIcons } from "primereact/api";
import {
  selectSettingState,
  setSettingData,
} from "../../../slices/settingsSlice";
import {
  CategoryDto,
  useFindCategoriesQuery,
  useFindGroupCategoriesQuery,
} from "../../../api/blooshApi";

const Categories: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const { categoryFilter } = useAppSelector(selectSettingState);
  const { data: groupCategories } = useFindGroupCategoriesQuery({
    page: 0,
    size: 10000,
  });
  const { isLoading, data } = useFindCategoriesQuery({
    page: 0,
    size: 10000,
  });

  const columns = [
    { field: "name", header: "Nazwa", sortable: true },
    {
      field: "groupCategoryId",
      header: "Grupa kategorii",
      sortable: true,
      body: (row: CategoryDto) =>
        groupCategories?.content?.find(
          (item) => item.id === row.groupCategoryId
        )?.name,
    },
    {
      field: "articleOnly",
      header: "Tylko dla artykułów",
      sortable: true,
      body: (row: CategoryDto) => (row.articleOnly ? "Tak" : "Nie"),
    },
    {
      field: "",
      header: "Akcje",
      body: categoryActionTemplate,
    },
  ];

  return (
    <>
      <TextInput
        id="categoryFilter"
        name="categoryFilter"
        label=""
        value={categoryFilter}
        setProperty={setSettingData}
        searchIcon
        placeholder="Szukaj"
      />
      <TableWrapper
        header="Kategorie"
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        globalFilter={categoryFilter}
        addButton={
          <Button
            onClick={() => navigate(routes.ADD)}
            label="Dodaj"
            icon={PrimeIcons.PLUS}
          />
        }
        stateKey="categories-list"
      />
    </>
  );
};

export default Categories;
