import React, {FC} from "react";
import {Button} from "primereact/button";
import {useNavigate, useParams} from "react-router-dom";
import articleCommentActionTemplate from "./Actions";
import {PrimeIcons} from "primereact/api";
import {useFindArticleCommentsQuery} from "../../../api/blooshApi";
import {routes} from "../../../app/routes";
import TableWrapper from "../../../components/table/TableWrapper";

const ArticleComments: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {id: articleId = ""} = useParams();
    const {isLoading, data} = useFindArticleCommentsQuery({
        page: 0,
        size: 10000,
        sort: ['createdDate,desc'],
        articleId: articleId
    });

    const columns = [
        {field: "username", header: "Username", sortable: true},
        {field: "content", header: "Treść", style: {textWrap: 'unset'}, sortable: true},
        {field: "createdDate", header: "Data dodania", sortable: true},
        {
            field: "",
            header: "",
            body: articleCommentActionTemplate,
        },
    ];

    const headerButtons = (
        <>
            <Button onClick={() => navigate(`/${routes.ARTICLES}`)} label="Wróć do listy artykułów"
                    icon={PrimeIcons.BACKWARD}/>
        </>
    );

    return (
        <>
            <TableWrapper
                header={`Komentarze do artykułu`}
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                addButton={headerButtons}
                stateKey="article-comments-list"
            />
        </>
    );
};

export default ArticleComments;
