import React, { FC } from "react";
import { useKeycloak } from "@react-keycloak-fork/web";
import { NavLink } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import { Image } from "primereact/image";
import image from "../logo.png";

const Template: FC<{ item: MenuItem, options: MenuItemOptions }> = ({
  item,
  options,
}) => {
  return (
    <NavLink
      to={`${item.url}`}
      className={({isActive, isPending}) => isPending ? options.className : isActive ? options.className + " nav-link-active" : options.className}
      onClick={options.onClick}
    >
      <i className={options.iconClassName} />{" "}
      <span className={options.labelClassName}>{item.label}</span>
    </NavLink>
  );
};

export const template = (item: MenuItem, options: MenuItemOptions) => (
  <Template item={item} options={options} />
);

const Nav = () => {
  const { keycloak } = useKeycloak();

  const items = [
    {
      label: "Artykuły",
      icon: "pi pi-fw pi-file",
      url: "/articles",
      template,
    },
    {
      label: "Promocje",
      icon: "pi pi-fw pi-images",
      url: "/promotions",
      template,
    },
    {
      label: "Produkty",
      icon: "pi pi-fw pi-calendar",
      url: "/products",
      template,
    },
    {
      label: "Ustawienia",
      icon: "pi pi-fw pi-pencil",
      url: "/settings",
      template,
    }
  ];

  return (
    <>
      <Menubar
          style={{maxWidth: 1940, margin: "0 auto", paddingLeft: 20, paddingRight: 20}}
        start={
          <div style={{ marginRight: 10, fontWeight: "bold", display: "flex" }}>
            <Image src={image} width="30" style={{marginTop: 2}} />
            <div style={{ margin: "auto", paddingLeft: 10 }}>
              Bloosh CRM
            </div>
          </div>
        }
        model={items}
        end={
          <Button
            label={
              !!keycloak.authenticated
                ? "Logout " + keycloak.tokenParsed?.preferred_username
                : "Login"
            }
            icon="pi pi-power-off"
            onClick={() =>
              !!keycloak.authenticated ? keycloak.logout() : keycloak.login()
            }
          />
        }
      />
    </>
  );
};

export default Nav;
