export const routes = {
  HOME: "",
  ARTICLES: "articles",
  COMMENTS: "comments",
  THREADS: "threads",
  PROMOTIONS: "promotions",
  PRODUCTS: "products",
  SETTINGS: "settings",
  CATEGORIES: "categories",
  GROUP_CATEGORIES: "group-cateegories",
  ADD: "add",
  EDIT: "edit",
};
