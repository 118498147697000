import React, { FC } from "react";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteProductCommentMutation } from "../../../api/blooshApi";
import { routes } from "../../../app/routes";

const Actions: FC<{ row: any }> = ({ row }) => {
  const navigate = useNavigate();
  const { productId: productId = "" } = useParams();
  const [visible, setVisible] = React.useState(false);

  const [deleteProductComment] = useDeleteProductCommentMutation();

  return (
    <>
      <div className="flex gap-2">
        <Button
          icon={PrimeIcons.DATABASE}
          onClick={() => navigate(`${row.id}/${routes.THREADS}`)}
          tooltip="Zobacz dyskusję"
        />
        <Button
          tooltip="Usuń"
          icon={PrimeIcons.TRASH}
          onClick={() => setVisible(true)}
        />
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="Jesteś pewny? Ten komentarz wraz z dyskusją i reakcjami zostanie usunięty na zawsze"
          header="Potwierdzenie"
          acceptLabel="Tak"
          rejectLabel="Nie"
          accept={() =>
            deleteProductComment({ productId: productId, id: row.id })
          }
        />
      </div>
    </>
  );
};

const productCommentActionTemplate = (row: any) => <Actions row={row} />;
export default productCommentActionTemplate;
