import React, { FC } from "react";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import productCommentActionTemplate from "./Actions";
import { PrimeIcons } from "primereact/api";
import {
  ProductCommentDto,
  useFindProductCommentsQuery,
} from "../../../api/blooshApi";
import { routes } from "../../../app/routes";
import TableWrapper from "../../../components/table/TableWrapper";

const ProductComments: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const { productId: productId = "" } = useParams();
  const { isLoading, data } = useFindProductCommentsQuery({
    page: 0,
    size: 10000,
    sort: ["createdDate,desc"],
    productId: productId,
  });

  const columns = [
    { field: "username", header: "Username", sortable: true },
    { field: "rate", header: "Ocena", sortable: true },
    {
      field: "buyAgain",
      header: "Czy kupi ponownie?",
      body: (row: ProductCommentDto) => (row.buyAgain ? "Tak" : "Nie"),
      sortable: true,
      style: { width: 10 },
    },
    {
      field: "content",
      header: "Treść",
      style: { textWrap: "unset", minWidth: 600 },
      sortable: true,
    },
    { field: "createdDate", header: "Data dodania", sortable: true },
    {
      field: "",
      header: "",
      body: productCommentActionTemplate,
      style: { minWidth: 200 },
    },
  ];

  const headerButtons = (
    <>
      <Button
        onClick={() => navigate(`/${routes.PRODUCTS}`)}
        label="Wróć do listy produktów"
        icon={PrimeIcons.BACKWARD}
      />
    </>
  );

  return (
    <>
      <TableWrapper
        header={`Komentarze do produktu`}
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        addButton={headerButtons}
        stateKey="product-comments-list"
      />
    </>
  );
};

export default ProductComments;
