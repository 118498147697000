import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryDto, GroupCategoryDto } from "../api/blooshApi";

type TSettingState = {
  category: CategoryDto;
  categoryFilter: string;
  groupCategory: GroupCategoryDto;
  groupCategoryFilter: string;
};

export const initialCategoryState: CategoryDto = {
  id: "",
  groupCategoryId: "",
  name: "",
  articleOnly: false,
};

export const initialGroupCategoryState: GroupCategoryDto = {
  id: "",
  name: "",
};

const initialState: TSettingState = {
  category: initialCategoryState,
  groupCategory: initialGroupCategoryState,
  categoryFilter: "",
  groupCategoryFilter: "",
};

export const settingsSlice = createSlice({
  name: "SETTING",
  initialState,
  reducers: {
    setSettingData: <Property extends keyof TSettingState>(
      state: TSettingState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TSettingState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
    setCategoryProperty: <Property extends keyof CategoryDto>(
      state: TSettingState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: CategoryDto[Property] }>
    ) => {
      const { property, value } = payload;
      state.category[property] = value;
    },
    setGroupCategoryProperty: <Property extends keyof GroupCategoryDto>(
      state: TSettingState,
      {
        payload,
      }: PayloadAction<{
        property: Property;
        value: GroupCategoryDto[Property];
      }>
    ) => {
      const { property, value } = payload;
      state.groupCategory[property] = value;
    },
  },
});

export const { setSettingData, setCategoryProperty, setGroupCategoryProperty } =
  settingsSlice.actions;

export const selectSettingState = (state: any) => state.settings;
export const selectCategoryState = (state: any) => state.settings.category;

export default settingsSlice.reducer;
