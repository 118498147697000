import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PromotionDto} from "../api/blooshApi";

type TPromotionState = {
    data: PromotionDto[];
    promotion: PromotionDto;
};

export const initialPromotionState: PromotionDto = {
    id: "",
    name: "",
    categoryId: "",
    price: 0,
    referralLink: "",
    expiredDate: "",
    priority: 0,
    imageKey: "",
    imageUrl: "",
};

const initialState: TPromotionState = {
    data: [],
    promotion: initialPromotionState
};

export const promotionsSlice = createSlice({
    name: "PROMOTION",
    initialState,
    reducers: {
        setPromotionData: <Property extends keyof TPromotionState>(
            state: TPromotionState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TPromotionState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setPromotionProperty: <Property extends keyof PromotionDto>(
            state: TPromotionState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: PromotionDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.promotion[property] = value;
        }
    },
});

export const {setPromotionData, setPromotionProperty} = promotionsSlice.actions;

export const selectPromotionState = (state: any) => state.promotions;

export default promotionsSlice.reducer;
