import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImageDto, ProductDto } from "../api/blooshApi";

type TProductState = {
  product: ProductDto;
  productFilter: string;
};

export const initialProductState: ProductDto = {
  id: "",
  name: "",
  shortContent: "",
  content: "",
  referralLink: "",
  images: [],
  categoryId: "",
  priority: 0,
  internalRate: 0,
};

const initialState: TProductState = {
  product: initialProductState,
  productFilter: "",
};

export const productsSlice = createSlice({
  name: "PRODUCT",
  initialState,
  reducers: {
    setProductData: <Property extends keyof TProductState>(
      state: TProductState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TProductState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
    setProductProperty: <Property extends keyof ProductDto>(
      state: TProductState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: ProductDto[Property] }>
    ) => {
      const { property, value } = payload;
      state.product[property] = value;
    },
    setProductImageProperty: <Property extends keyof ImageDto>(
      state: TProductState,
      {
        payload,
      }: PayloadAction<{ property: Property; index: number; value: ImageDto }>
    ) => {
      const { property, index, value } = payload;

      // @ts-ignore
      state.product.images[index][property] = value;

      console.log(state.product.images);
    },
  },
});

export const { setProductData, setProductProperty, setProductImageProperty } =
  productsSlice.actions;

export const selectProductState = (state: any) => state.products;

export default productsSlice.reducer;
