import { api } from "./api";
import { setPromotionData } from "../slices/promotionsSlice";
import { setProductData } from "../slices/productsSlice";
import { setSettingData } from "../slices/settingsSlice";
import { setArticleData } from "../slices/articlesSlice";
import { cacheTags } from "./cacheTags";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    findPromotionById: build.query<
      FindPromotionByIdApiResponse,
      FindPromotionByIdApiArg
    >({
      query: (queryArg) => ({ url: `/promotions/${queryArg.id}` }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPromotionData({ property: "promotion", value: data }));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updatePromotion: build.mutation<
      UpdatePromotionApiResponse,
      UpdatePromotionApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/${queryArg.id}`,
        method: "PUT",
        body: queryArg.promotionDto,
      }),
      invalidatesTags: [cacheTags.PROMOTIONS],
    }),
    deletePromotion: build.mutation<
      DeletePromotionApiResponse,
      DeletePromotionApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.PROMOTIONS],
    }),
    findProductCommentById: build.query<
      FindProductCommentByIdApiResponse,
      FindProductCommentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/comments/${queryArg.id}`,
      }),
    }),
    updateProductComment: build.mutation<
      UpdateProductCommentApiResponse,
      UpdateProductCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/comments/${queryArg.id}`,
        method: "PUT",
        body: queryArg.productCommentDto,
      }),
    }),
    deleteProductComment: build.mutation<
      DeleteProductCommentApiResponse,
      DeleteProductCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/comments/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.PRODUCT_COMMENTS],
    }),
    findProductById: build.query<
      FindProductByIdApiResponse,
      FindProductByIdApiArg
    >({
      query: (queryArg) => ({ url: `/products/${queryArg.id}` }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProductData({ property: "product", value: data }));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateProduct: build.mutation<
      UpdateProductApiResponse,
      UpdateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.id}`,
        method: "PUT",
        body: queryArg.productDto,
      }),
      invalidatesTags: [cacheTags.PRODUCTS],
    }),
    deleteProduct: build.mutation<
      DeleteProductApiResponse,
      DeleteProductApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.PRODUCTS],
    }),
    findProductCommentThreadById: build.query<
      FindProductCommentThreadByIdApiResponse,
      FindProductCommentThreadByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products-comments/${queryArg.productCommentId}/threads/${queryArg.id}`,
      }),
    }),
    updateProductCommentThread: build.mutation<
      UpdateProductCommentThreadApiResponse,
      UpdateProductCommentThreadApiArg
    >({
      query: (queryArg) => ({
        url: `/products-comments/${queryArg.productCommentId}/threads/${queryArg.id}`,
        method: "PUT",
        body: queryArg.productCommentThreadDto,
      }),
    }),
    deleteProductCommentThread: build.mutation<
      DeleteProductCommentThreadApiResponse,
      DeleteProductCommentThreadApiArg
    >({
      query: (queryArg) => ({
        url: `/products-comments/${queryArg.productCommentId}/threads/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.PRODUCT_COMMENT_THREADS],
    }),
    findGroupCategoryById: build.query<
      FindGroupCategoryByIdApiResponse,
      FindGroupCategoryByIdApiArg
    >({
      query: (queryArg) => ({ url: `/group-categories/${queryArg.id}` }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSettingData({ property: "groupCategory", value: data }));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateGroupCategory: build.mutation<
      UpdateGroupCategoryApiResponse,
      UpdateGroupCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/group-categories/${queryArg.id}`,
        method: "PUT",
        body: queryArg.groupCategoryDto,
      }),
    }),
    deleteGroupCategory: build.mutation<
      DeleteGroupCategoryApiResponse,
      DeleteGroupCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/group-categories/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    findCategoryById: build.query<
      FindCategoryByIdApiResponse,
      FindCategoryByIdApiArg
    >({
      query: (queryArg) => ({ url: `/categories/${queryArg.id}` }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSettingData({ property: "category", value: data }));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateCategory: build.mutation<
      UpdateCategoryApiResponse,
      UpdateCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/categories/${queryArg.id}`,
        method: "PUT",
        body: queryArg.categoryDto,
      }),
      invalidatesTags: [cacheTags.CATEGORIES],
    }),
    deleteCategory: build.mutation<
      DeleteCategoryApiResponse,
      DeleteCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/categories/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.CATEGORIES],
    }),
    findArticleById: build.query<
      FindArticleByIdApiResponse,
      FindArticleByIdApiArg
    >({
      query: (queryArg) => ({ url: `/articles/${queryArg.id}` }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setArticleData({ property: "article", value: data }));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateArticle: build.mutation<
      UpdateArticleApiResponse,
      UpdateArticleApiArg
    >({
      query: (queryArg) => ({
        url: `/articles/${queryArg.id}`,
        method: "PUT",
        body: queryArg.articleDto,
      }),
      invalidatesTags: [cacheTags.ARTICLES],
    }),
    deleteArticle: build.mutation<
      DeleteArticleApiResponse,
      DeleteArticleApiArg
    >({
      query: (queryArg) => ({
        url: `/articles/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.ARTICLES],
    }),
    findArticleCommentById: build.query<
      FindArticleCommentByIdApiResponse,
      FindArticleCommentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/articles/${queryArg.articleId}/comments/${queryArg.id}`,
      }),
    }),
    updateArticleComment: build.mutation<
      UpdateArticleCommentApiResponse,
      UpdateArticleCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/articles/${queryArg.articleId}/comments/${queryArg.id}`,
        method: "PUT",
        body: queryArg.articleCommentDto,
      }),
    }),
    deleteArticleComment: build.mutation<
      DeleteArticleCommentApiResponse,
      DeleteArticleCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/articles/${queryArg.articleId}/comments/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.ARTICLE_COMMENTS],
    }),
    findPromotions: build.query<
      FindPromotionsApiResponse,
      FindPromotionsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions`,
        params: {
          queryText: queryArg.queryText,
          categoryId: queryArg.categoryId,
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.PROMOTIONS,
                id,
              })),
              cacheTags.PROMOTIONS,
            ]
          : [cacheTags.PROMOTIONS],
    }),
    createPromotion: build.mutation<
      CreatePromotionApiResponse,
      CreatePromotionApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions`,
        method: "POST",
        body: queryArg.promotionDto,
      }),
      invalidatesTags: [cacheTags.PROMOTIONS],
    }),
    findProducts: build.query<FindProductsApiResponse, FindProductsApiArg>({
      query: (queryArg) => ({
        url: `/products`,
        params: {
          queryText: queryArg.queryText,
          categoryId: queryArg.categoryId,
          id: queryArg.id,
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.PRODUCTS,
                id,
              })),
              cacheTags.PRODUCTS,
            ]
          : [cacheTags.PRODUCTS],
    }),
    createProduct: build.mutation<
      CreateProductApiResponse,
      CreateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/products`,
        method: "POST",
        body: queryArg.productDto,
      }),
      invalidatesTags: [cacheTags.PRODUCTS],
    }),
    findProductComments: build.query<
      FindProductCommentsApiResponse,
      FindProductCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/comments`,
        params: {
          userId: queryArg.userId,
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.PRODUCT_COMMENTS,
                id,
              })),
              cacheTags.PRODUCT_COMMENTS,
            ]
          : [cacheTags.PRODUCT_COMMENTS],
    }),
    findProductCommentThreadsByProductCommentId: build.query<
      FindProductCommentThreadsByProductCommentIdApiResponse,
      FindProductCommentThreadsByProductCommentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products-comments/${queryArg.productCommentId}/threads`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.PRODUCT_COMMENT_THREADS,
                id,
              })),
              cacheTags.PRODUCT_COMMENT_THREADS,
            ]
          : [cacheTags.PRODUCT_COMMENT_THREADS],
    }),
    findGroupCategories: build.query<
      FindGroupCategoriesApiResponse,
      FindGroupCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/group-categories`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.GROUP_CATEGORIES,
                id,
              })),
              cacheTags.GROUP_CATEGORIES,
            ]
          : [cacheTags.GROUP_CATEGORIES],
    }),
    createGroupCategory: build.mutation<
      CreateGroupCategoryApiResponse,
      CreateGroupCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/group-categories`,
        method: "POST",
        body: queryArg.groupCategoryDto,
      }),
      invalidatesTags: [cacheTags.GROUP_CATEGORIES],
    }),
    findCategories: build.query<
      FindCategoriesApiResponse,
      FindCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/categories`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.CATEGORIES,
                id,
              })),
              cacheTags.CATEGORIES,
            ]
          : [cacheTags.CATEGORIES],
    }),
    createCategory: build.mutation<
      CreateCategoryApiResponse,
      CreateCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/categories`,
        method: "POST",
        body: queryArg.categoryDto,
      }),
      invalidatesTags: [cacheTags.CATEGORIES],
    }),
    findArticles: build.query<FindArticlesApiResponse, FindArticlesApiArg>({
      query: (queryArg) => ({
        url: `/articles`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.ARTICLES,
                id,
              })),
              cacheTags.ARTICLES,
            ]
          : [cacheTags.ARTICLES],
    }),
    createArticle: build.mutation<
      CreateArticleApiResponse,
      CreateArticleApiArg
    >({
      query: (queryArg) => ({
        url: `/articles`,
        method: "POST",
        body: queryArg.articleDto,
      }),
      invalidatesTags: [cacheTags.ARTICLES],
    }),
    findArticleComments: build.query<
      FindArticleCommentsApiResponse,
      FindArticleCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/articles/${queryArg.articleId}/comments`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result?.content?.map(({ id }) => ({
                type: cacheTags.ARTICLE_COMMENTS,
                id,
              })),
              cacheTags.ARTICLE_COMMENTS,
            ]
          : [cacheTags.ARTICLE_COMMENTS],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type FindPromotionByIdApiResponse = /** status 200 OK */ PromotionDto;
export type FindPromotionByIdApiArg = {
  id: string;
};
export type UpdatePromotionApiResponse = /** status 200 OK */ PromotionDto;
export type UpdatePromotionApiArg = {
  id: string;
  promotionDto: PromotionDto;
};
export type DeletePromotionApiResponse = /** status 200 OK */ void;
export type DeletePromotionApiArg = {
  id: string;
};
export type FindProductCommentByIdApiResponse =
  /** status 200 OK */ ProductCommentDto;
export type FindProductCommentByIdApiArg = {
  productId: string;
  id: string;
};
export type UpdateProductCommentApiResponse =
  /** status 200 OK */ ProductCommentDto;
export type UpdateProductCommentApiArg = {
  productId: string;
  id: string;
  productCommentDto: ProductCommentDto;
};
export type DeleteProductCommentApiResponse = /** status 200 OK */ void;
export type DeleteProductCommentApiArg = {
  productId: string;
  id: string;
};
export type FindProductByIdApiResponse = /** status 200 OK */ ProductDto;
export type FindProductByIdApiArg = {
  id: string;
};
export type UpdateProductApiResponse = /** status 200 OK */ ProductDto;
export type UpdateProductApiArg = {
  id: string;
  productDto: ProductDto;
};
export type DeleteProductApiResponse = /** status 200 OK */ void;
export type DeleteProductApiArg = {
  id: string;
};
export type FindProductCommentThreadByIdApiResponse =
  /** status 200 OK */ ProductCommentThreadDto;
export type FindProductCommentThreadByIdApiArg = {
  productCommentId: string;
  id: string;
};
export type UpdateProductCommentThreadApiResponse =
  /** status 200 OK */ ProductCommentThreadDto;
export type UpdateProductCommentThreadApiArg = {
  productCommentId: string;
  id: string;
  productCommentThreadDto: ProductCommentThreadDto;
};
export type DeleteProductCommentThreadApiResponse = /** status 200 OK */ void;
export type DeleteProductCommentThreadApiArg = {
  productCommentId: string;
  id: string;
};
export type FindGroupCategoryByIdApiResponse =
  /** status 200 OK */ GroupCategoryDto;
export type FindGroupCategoryByIdApiArg = {
  id: string;
};
export type UpdateGroupCategoryApiResponse =
  /** status 200 OK */ GroupCategoryDto;
export type UpdateGroupCategoryApiArg = {
  id: string;
  groupCategoryDto: GroupCategoryDto;
};
export type DeleteGroupCategoryApiResponse = /** status 200 OK */ void;
export type DeleteGroupCategoryApiArg = {
  id: string;
};
export type FindCategoryByIdApiResponse = /** status 200 OK */ CategoryDto;
export type FindCategoryByIdApiArg = {
  id: string;
};
export type UpdateCategoryApiResponse = /** status 200 OK */ CategoryDto;
export type UpdateCategoryApiArg = {
  id: string;
  categoryDto: CategoryDto;
};
export type DeleteCategoryApiResponse = /** status 200 OK */ void;
export type DeleteCategoryApiArg = {
  id: string;
};
export type FindArticleByIdApiResponse = /** status 200 OK */ ArticleDto;
export type FindArticleByIdApiArg = {
  id: string;
};
export type UpdateArticleApiResponse = /** status 200 OK */ ArticleDto;
export type UpdateArticleApiArg = {
  id: string;
  articleDto: ArticleDto;
};
export type DeleteArticleApiResponse = /** status 200 OK */ void;
export type DeleteArticleApiArg = {
  id: string;
};
export type FindArticleCommentByIdApiResponse =
  /** status 200 OK */ ArticleCommentDto;
export type FindArticleCommentByIdApiArg = {
  articleId: string;
  id: string;
};
export type UpdateArticleCommentApiResponse =
  /** status 200 OK */ ArticleCommentDto;
export type UpdateArticleCommentApiArg = {
  articleId: string;
  id: string;
  articleCommentDto: ArticleCommentDto;
};
export type DeleteArticleCommentApiResponse = /** status 200 OK */ void;
export type DeleteArticleCommentApiArg = {
  articleId: string;
  id: string;
};
export type FindPromotionsApiResponse = /** status 200 OK */ PagePromotionDto;
export type FindPromotionsApiArg = {
  queryText?: string;
  categoryId?: string[];
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreatePromotionApiResponse = /** status 200 OK */ PromotionDto;
export type CreatePromotionApiArg = {
  promotionDto: PromotionDto;
};
export type FindProductsApiResponse = /** status 200 OK */ PageProductDto;
export type FindProductsApiArg = {
  queryText?: string;
  categoryId?: string[];
  id?: string[];
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateProductApiResponse = /** status 200 OK */ ProductDto;
export type CreateProductApiArg = {
  productDto: ProductDto;
};
export type FindProductCommentsApiResponse =
  /** status 200 OK */ PageProductCommentDto;
export type FindProductCommentsApiArg = {
  productId: string;
  userId?: string;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type FindProductCommentThreadsByProductCommentIdApiResponse =
  /** status 200 OK */ PageProductCommentThreadDto;
export type FindProductCommentThreadsByProductCommentIdApiArg = {
  productCommentId: string;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type FindGroupCategoriesApiResponse =
  /** status 200 OK */ PageGroupCategoryDto;
export type FindGroupCategoriesApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateGroupCategoryApiResponse =
  /** status 200 OK */ GroupCategoryDto;
export type CreateGroupCategoryApiArg = {
  groupCategoryDto: GroupCategoryDto;
};
export type FindCategoriesApiResponse = /** status 200 OK */ PageCategoryDto;
export type FindCategoriesApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateCategoryApiResponse = /** status 200 OK */ CategoryDto;
export type CreateCategoryApiArg = {
  categoryDto: CategoryDto;
};
export type FindArticlesApiResponse = /** status 200 OK */ PageArticleDto;
export type FindArticlesApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateArticleApiResponse = /** status 200 OK */ ArticleDto;
export type CreateArticleApiArg = {
  articleDto: ArticleDto;
};
export type FindArticleCommentsApiResponse =
  /** status 200 OK */ PageArticleCommentDto;
export type FindArticleCommentsApiArg = {
  articleId: string;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type PromotionDto = {
  id?: string;
  name: string;
  categoryId: string;
  price: number;
  discountedPrice?: number;
  referralLink?: string;
  expiredDate?: string;
  priority?: number;
  imageKey?: string;
  imageUrl?: string;
};
export type ImageDto = {
  imageKey?: string;
  imageUrl?: string;
};
export type ProductCommentDto = {
  id?: string;
  productId: string;
  username?: string;
  rate: number;
  images?: ImageDto[];
  content: string;
  buyAgain: boolean;
  reactionCount: number;
  createdDate?: string;
};
export type ProductDto = {
  id?: string;
  name: string;
  shortContent: string;
  content: string;
  referralLink?: string;
  images?: ImageDto[];
  categoryId: string;
  priority: number;
  internalRate?: number;
  userReviewRate?: number;
  totalReviewsCount?: number;
  buyAgainRate?: number;
};
export type ProductCommentThreadDto = {
  id?: string;
  productCommentId: string;
  username?: string;
  content: string;
  createdDate?: string;
};
export type GroupCategoryDto = {
  id?: string;
  name: string;
};
export type CategoryDto = {
  id?: string;
  groupCategoryId?: string;
  name: string;
  articleOnly: boolean;
};
export type ArticleDto = {
  id?: string;
  title: string;
  shortContent: string;
  content: string;
  imageKey: string;
  categoryId: string;
  priority: number;
  imageUrl?: string;
  createdDate?: string;
  mentionedProductIds?: string[];
};
export type ArticleCommentDto = {
  id?: string;
  articleId: string;
  username?: string;
  content: string;
  createdDate?: string;
};
export type SortObject = {
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
};
export type PageableObject = {
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
  offset?: number;
  sort?: SortObject;
};
export type PagePromotionDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: PromotionDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageProductDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: ProductDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageProductCommentDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: ProductCommentDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageProductCommentThreadDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: ProductCommentThreadDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageGroupCategoryDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: GroupCategoryDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageCategoryDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: CategoryDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageArticleDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: ArticleDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export type PageArticleCommentDto = {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  numberOfElements?: number;
  size?: number;
  content?: ArticleCommentDto[];
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
};
export const {
  useFindPromotionByIdQuery,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
  useFindProductCommentByIdQuery,
  useUpdateProductCommentMutation,
  useDeleteProductCommentMutation,
  useFindProductByIdQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useFindProductCommentThreadByIdQuery,
  useUpdateProductCommentThreadMutation,
  useDeleteProductCommentThreadMutation,
  useFindGroupCategoryByIdQuery,
  useUpdateGroupCategoryMutation,
  useDeleteGroupCategoryMutation,
  useFindCategoryByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useFindArticleByIdQuery,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useFindArticleCommentByIdQuery,
  useUpdateArticleCommentMutation,
  useDeleteArticleCommentMutation,
  useFindPromotionsQuery,
  useCreatePromotionMutation,
  useFindProductsQuery,
  useCreateProductMutation,
  useFindProductCommentsQuery,
  useFindProductCommentThreadsByProductCommentIdQuery,
  useFindGroupCategoriesQuery,
  useCreateGroupCategoryMutation,
  useFindCategoriesQuery,
  useCreateCategoryMutation,
  useFindArticlesQuery,
  useCreateArticleMutation,
  useFindArticleCommentsQuery,
} = injectedRtkApi;
