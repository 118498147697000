import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../app/routes";
import {useDeleteArticleMutation} from "../../api/blooshApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [deleteVisible, setDeleteVisible] = React.useState(false);

    const [deleteArticle] = useDeleteArticleMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    icon={PrimeIcons.DATABASE}
                    onClick={() => navigate(`${row.id}/${routes.COMMENTS}`)}
                    label="Zobacz komentarze"
                />
                <Button
                    icon={PrimeIcons.PENCIL}
                    onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
                    label="Edytuj"
                />
                <Button
                    icon={PrimeIcons.TRASH}
                    onClick={() => setDeleteVisible(true)}
                    label="Usuń"
                />
                <ConfirmDialog
                    visible={deleteVisible}
                    onHide={() => setDeleteVisible(false)}
                    message="Jesteś pewny? Artykuł zostanie usunięty na zawsze z systemu"
                    header="Potwierdzenie"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                    accept={() => deleteArticle({id: row.id})}
                />
            </div>
        </>
    );
};

const articleActionTemplate = (row: any) => <Actions row={row}/>;
export default articleActionTemplate;
