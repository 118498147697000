import React, { FC } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/routes";

const Settings: FC = () => {
  const navigate = useNavigate();

  const menuButton = (label: string, route: string) => (
    <Button label={label} onClick={() => navigate(route)} />
  );

  return (
    <div>
      <h1 className="text-4xl ml-3">Ustawienia</h1>

      <div className="grid flex-row gap-2 m-3">
        {menuButton("Kategorie", routes.CATEGORIES)}
        {menuButton("Grupy kategorii", routes.GROUP_CATEGORIES)}
      </div>
    </div>
  );
};

export default Settings;
