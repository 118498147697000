import React, {CSSProperties, FC} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {InputTextarea} from "primereact/inputtextarea";
import MDEditor from "@uiw/react-md-editor";

type TMdEditorInputProps = {
    id: string;
    name: string;
    value: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
    style?: CSSProperties,
    index?: number
};

const MdEditorInput: FC<TMdEditorInputProps> = ({
                                                    id,
                                                    name,
                                                    value,
                                                    label,
                                                    setProperty,
                                                    style,
                                                    index,
                                                }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field pt-2 pb-2 w-full">
        <span className="p-float-label">
            <label htmlFor={name}>{label}</label>
                  <MDEditor
                      id={id}
                      value={value}
                      onChange={(e) =>
                          dispatch(setProperty({property: name, value: e, index: index}))
                      }
                      style={style}
                      className={"w-full"}
                  />
            {/*<MDEditor.Markdown source={value} style={{whiteSpace: 'pre-wrap'}}/>*/}
        </span>
            </div>
        </>
    );
};

export default MdEditorInput;
