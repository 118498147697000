import React, { FC } from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import { routes } from "../../../app/routes";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/form/TextInput";
import { useAppSelector } from "../../../app/hooks";
import groupCategoryActionTemplate from "./Actions";
import { PrimeIcons } from "primereact/api";
import {
  selectSettingState,
  setSettingData,
} from "../../../slices/settingsSlice";
import { useFindGroupCategoriesQuery } from "../../../api/blooshApi";

const GroupCategories: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const { groupCategoryFilter } = useAppSelector(selectSettingState);
  const { isLoading, data } = useFindGroupCategoriesQuery({
    page: 0,
    size: 10000,
  });

  const columns = [
    { field: "name", header: "Nazwa", sortable: true },
    {
      field: "",
      header: "Akcje",
      body: groupCategoryActionTemplate,
    },
  ];

  return (
    <>
      <TextInput
        id="groupCategoryFilter"
        name="groupCategoryFilter"
        label=""
        value={groupCategoryFilter}
        setProperty={setSettingData}
        searchIcon
        placeholder="Szukaj"
      />
      <TableWrapper
        header="Grupy kategorii"
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        globalFilter={groupCategoryFilter}
        addButton={
          <Button
            onClick={() => navigate(routes.ADD)}
            label="Dodaj"
            icon={PrimeIcons.PLUS}
          />
        }
        stateKey="group-categories-list"
      />
    </>
  );
};

export default GroupCategories;
